import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useRouter } from "next/router";
import EsquireLogo from "../components/EsquireLogo";
import Spinner from "../components/Spinner";
import { useSnackbar } from "notistack";
import { FirebaseError } from "firebase/app";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogin = async () => {
    try {
      await signIn(email, password);
      router.push("/");
    } catch (e) {
      console.log(e);
      if(e instanceof FirebaseError){
        enqueueSnackbar(e.message, { variant: "error" });
      }
      setLoading(false);
    }
  };

  return (
    <div className="grid place-content-center w-screen h-screen">
      <div className="w-[300px] shadow-md flex flex-col rounded-xl backdrop-blur border border-solid border-zinc-200">
        <div className="flex flex-col items-center pt-4 ">
          <EsquireLogo className="w-32" />
        </div>
        <form className="p-4" onSubmit={(e) => e.preventDefault()}>
          <TextField
            label="Email"
            id="outlined-size-small"
            defaultValue=""
            fullWidth
            margin="dense"
            size="small"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            type="password"
            label="Password"
            id="outlined-size-small"
            defaultValue=""
            fullWidth
            margin="dense"
            size="small"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex justify-end">
            <Button variant="contained" size="medium" onClick={handleLogin}>
              {loading?<Spinner/>:"Login"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
